var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"l-view l-container--full-height l-container--large"},[_c('Sidebar',{staticClass:"o-sidebar--with-img"},[_c('img',{staticClass:"o-sidebar__img",attrs:{"src":require("@/assets/images/generic/afbeelding-evenement-4x.jpg"),"alt":"Open"}})]),_c('div',{staticClass:"o-page o-page--white"},[(!_vm.showForgotPassword)?_c('div',{staticClass:"p-login__content"},[_c('h1',{staticClass:"p-login__title"},[_vm._v("Welkom bij ikwatersport.be")]),_vm._m(0),_c('ValidationObserver',{attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passes = ref.passes;
return [_c('form',{staticClass:"p-login__form",attrs:{"id":"registerForm"},on:{"submit":function($event){$event.preventDefault();return passes(_vm.login)}}},[_c('ValidationProvider',{staticClass:"a-input__label",attrs:{"vid":"confirm|email","rules":"required","tag":"label"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_vm._v(" E-Mail "),_c('input',{directives:[{name:"model",rawName:"v-model.lazy",value:(_vm.email),expression:"email",modifiers:{"lazy":true}}],staticClass:"a-input",attrs:{"type":"email","name":"search","placeholder":"E-mail"},domProps:{"value":(_vm.email)},on:{"change":function($event){_vm.email=$event.target.value}}}),_c('span',{staticClass:"a-input__validation-message"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('ValidationProvider',{staticClass:"a-input__label",attrs:{"vid":"confirm","rules":"required","tag":"label"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_vm._v(" Wachtwoord "),_c('input',{directives:[{name:"model",rawName:"v-model.lazy",value:(_vm.password),expression:"password",modifiers:{"lazy":true}}],staticClass:"a-input",attrs:{"type":"password","name":"search","placeholder":"Wachtwoord"},domProps:{"value":(_vm.password)},on:{"change":function($event){_vm.password=$event.target.value}}}),_c('a',{staticClass:"a-link--underline a-link--light",on:{"click":function($event){return _vm.toggleForgotPassword()}}},[_vm._v("Wachtwoord vergeten")]),_c('span',{staticClass:"a-input__validation-message"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('button',{staticClass:"a-button a-button--primary",attrs:{"type":"submit"}},[_vm._v("Inloggen")])],1)]}}],null,false,409867717)})],1):_vm._e(),(_vm.showForgotPassword)?_c('div',{staticClass:"p-login__content"},[_c('h1',{staticClass:"p-login__title"},[_vm._v("Reset uw wachtwoord")]),_c('p',[_vm._v(" Terug naar "),_c('a',{staticClass:"a-link--underline",on:{"click":function($event){return _vm.toggleForgotPassword()}}},[_vm._v("inloggen")])]),_c('ValidationObserver',{attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passes = ref.passes;
return [_c('form',{staticClass:"p-login__form",attrs:{"id":"forgotPasswordForm"},on:{"submit":function($event){$event.preventDefault();return passes(_vm.forgotPassword)}}},[_c('ValidationProvider',{staticClass:"a-input__label",attrs:{"vid":"confirm|email","rules":"required","tag":"label"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_vm._v(" E-Mail "),_c('input',{directives:[{name:"model",rawName:"v-model.lazy",value:(_vm.email),expression:"email",modifiers:{"lazy":true}}],staticClass:"a-input",attrs:{"type":"email","name":"search","placeholder":"E-mail"},domProps:{"value":(_vm.email)},on:{"change":function($event){_vm.email=$event.target.value}}}),_c('span',{staticClass:"a-input__validation-message"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('button',{staticClass:"a-button a-button--primary",attrs:{"type":"submit"}},[_vm._v("Reset wachtwoord")])],1)]}}],null,false,4073097337)})],1):_vm._e()])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',[_vm._v(" Heb je nog geen account? "),_c('a',{staticClass:"a-link--underline",attrs:{"href":"/register"}},[_vm._v("Maak er een aan.")])])}]

export { render, staticRenderFns }